<template>
  <div>
    <Card>
      <template #header>Görüşmeler</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addFileModal = true"
              >Yeni Görüşme</b-button
            ></b-form-group
          >
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Görüşmeler Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <button class="btn p-0">
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon-download text-dark text-md"></i>
          </button>
        </div>
      </template>
    </List>
    <b-modal v-model="addFileModal" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i> Yeni Görüşme</h4>
      </template>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="date">Görüşme Tarihi</label>
            <input
              type="date"
              class="form-control"
              id="date"
              v-model="interview_date"
            />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label for="gorusmeYapan">Görüşme Yapan </label>
            <select
              name="gorusmeYapan"
              id="gorusmeYapan"
              class="form-control"
              v-model="interviewer"
            >
              <option value="" selected>Seçiniz</option>
              <option value="1">a</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="gorusmeYapilanKisi">Görüşme Yapılan Kişi</label>
            <MultiSelect
              :options="gorusmeYapilanKisi"
              :multiple="false"
              v-model="interviewed_person"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="aliciMi">Potansiyel Alıcı mı?</label>
            <select
              name="aliciMi"
              id="aliciMi"
              class="form-control"
              v-model="potential_buyer"
            >
              <option value="1">Evet</option>
              <option value="0">Hayır</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="fiyatGorus">Gayrimenkul Fiyat Görüşü?</label>
            <select
              name="fiyatGorus"
              id="fiyatGorus"
              class="form-control"
              v-model="repo"
            >
              <option value="1">Az</option>
              <option value="2">Orta</option>
              <option value="3">Çok</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="haberVer"
              >Yer Gösterimi Yapılacağında Haber Verilecek mi?</label
            >
            <select
              name="haberVer"
              id="haberVer"
              class="form-control"
              v-model="ygyhaber"
            >
              <option value="1">Evet</option>
              <option value="0">Hayır</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="ihaleHaber"
              >İhale Başladığında Haber Verilecek mi?</label
            >
            <select
              name="ihaleHaber"
              id="ihaleHaber"
              class="form-control"
              v-model="ibhaberv"
            >
              <option value="1">Evet</option>
              <option value="0">Hayır</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Görüşme Açıklama</label>
            <textarea
              name=""
              id=""
              rows="2"
              class="form-control"
              v-model="interview_description"
            ></textarea>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addFileModal = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="save" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "systemUsers",
  data() {
    return {
      gorusmeYapilanKisi: [
        { text: "Melisa Eldem", value: "1" },
        { text: "Rasim Karadeniz", value: "2" },
        { text: "Ahmet Aydın", value: "3" },
      ],
      addFileModal: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Görüşmeyi Yapan", value: "interviewer" },
        { text: "Müşteri", value: "interviewed_person" },
        { text: "Tarih", value: "interview_date" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",

      interview_date: "",
      interviewer: "",
      interviewed_person: "",
      potential_buyer: "",
      repo: "",
      ygyhaber: "",
      ibhaberv: "",
      interview_description: "",
    };
  },

  created() {
    this.getList();
  },
  methods: {
    save() {
      let data = {
        interview_date: moment(this.interview_date).format('DD/MM/YYYY'),
        interviewer: this.interviewer,
        interviewed_person: this.interviewed_person.value,
        potential_buyer: this.potential_buyer,
        repo: this.repo,
        ygyhaber: this.ygyhaber,
        ibhaberv: this.ibhaberv,
        interview_description: this.interview_description,
        user_token: this.myUser,
        advert_id: this.$route.params.id,
      };
      this.$ApiService.post("advert/meeting", data)
      .then((res) => {
        this.$generateNotification(this, "success","Görüşme Eklenmiştir."),
        this.getList();
          this.interview_date= "",
          this.interviewer= "",
          this.interviewed_person= "",
          this.potential_buyer= "",
          this.repo= "",
          this.ygyhaber= "",
          this.ibhaberv= "",
          this.interview_description= "",
        this.addFileModal = false;

      })
      .catch((err)=>{
        this.$generateNotification(this,"error","Görüşme Eklenemedi.")
      })
    },
    getList() {
      this.loading = true;
      this.items = [];

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `advert/meeting?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&advert_id=${
        this.$route.params.id
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }
      this.$dbFunctions
        .get(query)
        .then((res) => {
          if (res.status.code == 200) {
            this.items = res.response.data;
            this.totalItems = res.response.data.length;
            this.pagination = {
              current_page: 1,
              total_pages: Math.ceil(res.response.data.length / 10),
              total_items: this.totalItems,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
